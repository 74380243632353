import React, { createContext, useContext, useMemo } from "react";
import { useQuery } from "@apollo/client";

import { GetIntegrations } from "@api/query/integrations";
import { GetIntegrationsQuery, GetIntegrationsQueryVariables } from "@api/graphql/types";
import { getErrorMessage } from "@utils/errors";
import { useFeedbackContext } from "~/components/Feedback";
import { IntegrationFieldInput, useEditCompanyIntegrations } from "@api/integrations";

interface IIntegrationsContextType {
	integrations?: NonNullable<GetIntegrationsQuery["integrations"]>["nodes"];
	loading: boolean;
	createField: (v: IntegrationFieldInput) => void;
	updateField: (id: number, v: string) => void;
}

const defaultContext: IIntegrationsContextType = {
	integrations: undefined,
	loading: false,
	createField: (v: IntegrationFieldInput) => {
		/* dummy Fn */
	},
	updateField: (id: number, v: string) => {
		/* dummy Fn */
	},
};

const IntegrationsContext = createContext<IIntegrationsContextType>(defaultContext);

export const useIntegrationsContext = () => {
	const context = useContext(IntegrationsContext);
	if (!context) {
		throw new Error(
			"You cannot use the Integrations Context outside of its Provider!",
		);
	}
	return context;
};

const useIntegrationsContextValue = ({ companyId }: { companyId: string }) => {
	const { data, loading } = useQuery<
		GetIntegrationsQuery,
		GetIntegrationsQueryVariables
	>(GetIntegrations, {
		variables: {
			companyId: Number(companyId),
		},
		onError(err) {
			console.error(err);
		},
	});

	const integrations = useMemo(() => {
		let arr = [...(data?.integrations?.nodes || [])];
		arr.sort((a, b) => {
			if (a.enabled && a.companyIntegrations.nodes.length) {
				return -1;
			}

			if (b.enabled && b.companyIntegrations.nodes.length) {
				return 1;
			}

			if (a.enabled && !b.enabled) {
				return -1;
			}

			if (!a.enabled && b.enabled) {
				return 1;
			}

			return 0;
		});

		return arr;
	}, [data]);

	const { handleOpenFeedback } = useFeedbackContext();

	const actions = useEditCompanyIntegrations();

	const createField = async (v: IntegrationFieldInput) => {
		try {
			const result = await actions.createField(v);
			console.log("createField", v, result);
			if (result.errors) {
				throw result.errors;
			}

			handleOpenFeedback({
				message: "Your changes has been saved!",
				severity: "success",
			});
			return result;
		} catch (error) {
			console.error(error);
			handleOpenFeedback({
				message: getErrorMessage(error),
				severity: "error",
			});
		}
		return false;
	};

	const updateField = async (id: number, value: string) => {
		try {
			const result = await actions.updateField(id, value);
			console.log("updateField", id, value, result);
			if (result.errors) {
				throw result.errors;
			}

			handleOpenFeedback({
				message: "Your changes has been saved!",
				severity: "success",
			});
			return result;
		} catch (error) {
			console.error(error);
			handleOpenFeedback({
				message: getErrorMessage(error),
				severity: "error",
			});
		}
		return false;
	};

	return {
		integrations,
		loading,
		createField,
		updateField,
	};
};

export const IntegrationsProvider: React.FC<any> = (props) => {
	const { children } = props;
	const contextValue = useIntegrationsContextValue(children?.props?.params);

	return (
		<IntegrationsContext.Provider value={contextValue}>
			{children}
		</IntegrationsContext.Provider>
	);
};
