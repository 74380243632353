import React from "react";

import { createCompanyView, ViewComponent } from "../../_lib/view";
import { IntegrationsProvider } from "./_lib/context";

import { IntegrationsDisplay } from "./_lib/IntegrationsDisplay";

const IntegrationsView: ViewComponent = (props) => {
	return (
		<IntegrationsProvider>
			<IntegrationsDisplay {...props} />
		</IntegrationsProvider>
	);
};

export default createCompanyView(IntegrationsView, {
	title: "Integrations",
	internalPad: true,
});
