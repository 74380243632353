import React from "react";

import Box from "@material-ui/core/Box";

import { customStyles } from "./styles";

import type { IIntegrationCardProps } from "./types";
import { IntegrationSwitch } from "../IntegrationSwitch";

const IntegrationCard = ({
	companyId,
	integration,
	inputs,
}: IIntegrationCardProps) => {
	const styles = customStyles();
	return (
		<>
			<Box className={styles.content}>
				<IntegrationSwitch
					companyId={companyId}
					integration={integration}
				/>
				{!!inputs && <Box className={styles.inputs}>
					{inputs}
				</Box>}
			</Box>
		</>
	);
};

export default IntegrationCard;
