import gql from "graphql-tag";
import { FieldConfig } from "./fields";

export const GetIntegrations = gql`
	query GetIntegrations($companyId: Int) {
		integrations(condition: {enabled: true}) {
			nodes {
				id
				name
				displayName
				enabled

				fieldConfigs {
					nodes {
						...FieldConfig

						fieldsByConfig(condition: {companyId: $companyId}) {
							nodes {
								id
								topic
								value
							}
						}
					}
				}

				companyIntegrations(condition: {companyId: $companyId}) {
					nodes {
						id
						enabled
					}
				}

				productIntegrations {
					nodes {
						id
						enabled
						product {
							id
							name
							displayName
						}
					}
				}
			}
		}
	}
	${FieldConfig}
`;

export const CreateCompanyIntegration = gql`
	mutation CreateCompanyIntegration($input: CreateCompanyIntegrationInput!) {
		createCompanyIntegration(input: $input) {
			company {
				id
				name
				companyIntegrations {
					nodes {
						id
						enabled
						integration {
							id
							name
							displayName
							enabled
						}
					}
				}
			}
		}
	}
`;

export const UpdateCompanyIntegration = gql`
	mutation UpdateCompanyIntegration($input: UpdateCompanyIntegrationInput!) {
		updateCompanyIntegration(input: $input) {
			companyIntegration {
				id
				enabled
				integration {
					id
					name
					displayName
					enabled
				}
			}
		}
	}
`;


export const CreateCompanyProductIntegration = gql`
	mutation CreateCompanyProductIntegration($input: CreateCompanyProductIntegrationInput!) {
		createCompanyProductIntegration(input: $input) {
			companyProductIntegration {
				id
				enabled
				companyProduct {
					id
					enabled
					product {
						id
						name
						displayName
						enabled
					}
				}
				
				companyIntegration {
					id
					enabled
					integration {
						id
						name
						displayName
						enabled
					}
				}
			}
		}
	}
`;

export const UpdateCompanyProductIntegration = gql`
	mutation UpdateCompanyProductIntegration($input: UpdateCompanyProductIntegrationInput!) {
		updateCompanyProductIntegration(input: $input) {
			companyProductIntegration {
				id
				enabled
				companyProduct {
					id
					enabled
					product {
						id
						name
						displayName
						enabled
					}
				}
				
				companyIntegration {
					id
					enabled
					integration {
						id
						name
						displayName
						enabled
					}
				}
			}
		}
	}
`;
