import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		width: "100%",
	},
	form: {
		width: "100%",
		[theme.breakpoints.up(theme.breakpoints.values.md)]: {
			width: "50%",
		},
		minHeight: "100vh",
	},
	formContent: {
		backgroundColor: "transparent",
		boxShadow: "none",
	},
	button: {
		transition: "none",
		width: "100%",
		"& .MuiGrid-item": {
			padding: "0 !important",
		},
	},
	labelWithLogo: {
		display: "flex",
		alignItems: "center",
		gap: 8,
	},
	productNav: {
		display: "none",
		[theme.breakpoints.up(theme.breakpoints.values.md)]: {
			display: "flex",
			flexDirection: "column",
			width: "33%",
			gap: 18,
			marginTop: 48,
			padding: 12,
			fontSize: 12,
		},
	},
	toggle: {
		display: "flex",
		justifyContent: "space-between",
		marginRight: 0,
		marginLeft: 8,
	},
	spinner: {
		width: "48px !important",
		height: "48px !important",
		alignSelf: "center",
		margin: "25vh auto",
		display: "flex",
	},
	lenderSelect: {
		width: "50%",
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			display: "none",
		},
	},
}));
