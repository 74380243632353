import { useMemo } from "react";
import { FetchResult, useMutation } from "@apollo/client";
import { captureError } from "@utils/sentry";

import { CreateField, UpdateField } from "./query/fields";
import { CreateCompanyIntegration, UpdateCompanyIntegration, GetIntegrations } from "./query/integrations";

import {
	CreateCompanyIntegrationMutation,
	CreateCompanyIntegrationMutationVariables,
	CreateFieldMutation,
	CreateFieldMutationVariables,
	UpdateCompanyIntegrationMutation,
	UpdateCompanyIntegrationMutationVariables,
	UpdateFieldMutation,
	UpdateFieldMutationVariables,
} from "./graphql/types";

export interface IntegrationFieldInput {
	value: string;
	companyId: number;
	companyIntegrationId: number;
	config: number;
}

export interface ICompanyIntegrationsActions {
	createField: (input: IntegrationFieldInput) => Promise<FetchResult<any>>
	updateField: (id: number, value: string) => Promise<FetchResult<any>>
	createCompanyIntegration: (
		companyId: number,
		integrationId: number,
	) => Promise<FetchResult<CreateCompanyIntegrationMutation>>;
	updateCompanyIntegration: (
		id: number,
		enabled: boolean,
	) => Promise<FetchResult<UpdateCompanyIntegrationMutation>>;
}

export const useEditCompanyIntegrations = (): ICompanyIntegrationsActions => {
	const [doCreateField] = useMutation<
		CreateFieldMutation,
		CreateFieldMutationVariables
	>(CreateField, {
		refetchQueries: [GetIntegrations],
		onError: (err) => {
			captureError(err.message, {
				error: err,
			});
		},
	});

	const [doUpdateField] = useMutation<
		UpdateFieldMutation,
		UpdateFieldMutationVariables
	>(UpdateField, {
		refetchQueries: [GetIntegrations],
		onError: (err) => {
			captureError(err.message, {
				error: err,
			});
		},
	});

	const [doCreateCompanyIntegration] = useMutation<
		CreateCompanyIntegrationMutation,
		CreateCompanyIntegrationMutationVariables
	>(CreateCompanyIntegration, {
		refetchQueries: [GetIntegrations],
		onError: (err) => {
			captureError(err.message, {
				error: err,
			});
		},
	});

	const [doUpdateCompanyIntegration] = useMutation<
		UpdateCompanyIntegrationMutation,
		UpdateCompanyIntegrationMutationVariables
	>(UpdateCompanyIntegration, {
		refetchQueries: [GetIntegrations],
		onError: (err) => {
			captureError(err.message, {
				error: err,
			});
		},
	});

	const actions: ICompanyIntegrationsActions = useMemo(
		() => ({
			createField: (field: IntegrationFieldInput) =>
				doCreateField({
					variables: {
						input: { field },
					},
				}),
			updateField: (id: number, value: string) =>
				doUpdateField({
					variables: {
						input: {
							id, patch: { value }
						},
					},
				}),
			createCompanyIntegration: (companyId: number, integrationId: number) =>
				doCreateCompanyIntegration({
					variables: {
						input: {
							companyIntegration: {
								companyId,
								integrationId,
							},
						},
					},
				}),
			updateCompanyIntegration: (id: number, enabled: boolean) =>
				doUpdateCompanyIntegration({
					variables: {
						input: {
							id,
							patch: { enabled }
						},
					},
				}),
		}),
		[doCreateCompanyIntegration, doUpdateCompanyIntegration],
	);

	return actions;
};
