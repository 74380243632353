import { makeStyles } from "@material-ui/core/styles";

export const customStyles = makeStyles(() => ({
	content: {
		backgroundColor: "#FFFFFF",
		display: "flex",
		flexDirection: "column",
		gap: 0,
		boxShadow: "0px 3px 7px 1px rgba(0, 0, 0, 0.15)",
		borderRadius: 14,
		width: "100%",
		marginBottom: 24,
		padding: 14,
		overflow: "hidden",
	},
	inputs: {
		marginTop: 10,
		display: "flex",
		flexDirection: "column",
		gap: 20,
	},
	displayName: {
		fontSize: 16,
	},
	description: {
		margin: "24px 0",
		display: "flex",
		gap: 8,
	},
	separator: {
		width: "calc(100% + 28px)",
		marginLeft: "-14px",
		border: "1px solid #DBE2EB",
	},
}));
